import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import { LOGIN_RESTORED, LOGOUT } from '../ducks/auth'
import Toasts from './Toasts'
import BuildPane from './BuildPane'
import layoutStyles from '../styles/Layout.module.scss'
import Nav from '../components/Nav'

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(LOGOUT()),
    restoreSession: ({ id, token }) => dispatch(LOGIN_RESTORED({ id, token }))
  }
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
    const cachedUser = localStorage.getItem('currentUser')
    if (cachedUser) {
      this.props.restoreSession(JSON.parse(cachedUser))
    }
  }
  render() {
    return (
      <Router>
        <div>
          <Toasts position="bottomRight" />
          <div className={layoutStyles.root}>
            {this.props.currentUser && <Nav logout={this.props.logout} />}
            <div className={layoutStyles.content}>
              {this.props.currentUser && <BuildPane />}
              {this.props.children}
            </div>
          </div>
        </div>
      </Router>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
