import { connect } from 'react-redux'
import { FILTER_RESPONSES, REQUEST_RESPONSES, DELETE_RESPONSE } from '../ducks/responses'
import Responses from '../components/Responses'

const mapStateToProps = state => {
  return {
    responses: Object.values(state.responses.byId).filter(response => !state.responses.typeFilter || response.type === state.responses.typeFilter).map(r => ({...r, key: r._id })),
    loading: state.responses.loading,
    lastEvaluated: state.responses.lastEvaluated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterTypes: type => dispatch(FILTER_RESPONSES(type)),
    deleteResponse: response => dispatch(DELETE_RESPONSE(response)),
    load: ({ limit, startAt }) => dispatch(REQUEST_RESPONSES({ limit, startAt}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Responses)
