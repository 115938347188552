import axios from 'axios'

class SocketClient {
  constructor(baseURL) {
    if (this.instance) return this.instance
    this.instance = this
    this.baseURL = baseURL
    this.token = localStorage.getItem('currentUser')
    this.connected = false
    this.connectInvoked = false

    this.handlers = {
      message: [({ data }) => {
        const { event } = JSON.parse(data)
        if (event === 'pong') {
          this.heartbeat()
        }
      }],
      reconnect: [],
      close: [() => {
        this.connected = false
        clearTimeout(this.pingTimeout)
      }],
      error: [(err) => {
        /* eslint-disable-next-line */
        console.error(err)
        this.connected = false
        if (this.connection) {
          this.connection.close()
        }
        clearTimeout(this.pingTimeout)
      }],
      open: [(e) => {
        this.connected = true
        this.heartbeat()
      }]
    }
  }

  heartbeat = () => {
    clearTimeout(this.pingTimeout)
    clearTimeout(this.failTimeout)
    this.pingTimeout = setTimeout(() => {
      this.send('ping')
      this.failTimeout = setTimeout(() => {
        console.warn(`skipped a heartbeat`)
        this.connected = false
        this.connection.close()
        this.handlers.close.forEach(handler => handler())
      }, 5000)
    }, 5000)
  }

  send = (data) => {
    if (this.connected && this.connection.readyState === 1) {
      this.connection.send(data)
    }
  }

  connect = () => {
    this.connectInvoked = true
    const connection = new WebSocket(this.baseURL, this.token)
    this._bindListeners(connection)
  }

  on = (event, cb) => {
    if (this.handlers[event]) {
      this.handlers[event].push(cb)
      if (event !== 'reconnect' && this.connection) {
        this.connection.addEventListener(event, cb)
      }
    }
  }

  off = (event, cb) => {
    if (this.handlers[event] && this.handlers[event].includes(cb)) {
      this.handlers[event].splice(this.handlers[event].indexOf(cb), 1)
      if (event !== 'reconnect') {
        this.connection.removeEventListener(event, cb)
      }
    }
  }

  onAsync = (event) => new Promise((resolve) => { this.on(event, resolve) })

  reconnect = () => {
    console.log('reconnecting')
    this.reconnecting = true
    if (this.connection) {
      this.connection.close()
    }
    this.connect()
    setTimeout(() => {
      this._pollConnection()
      if (this.connected) {
        console.log('reconnection successful')
        this.reconnecting = false
        this.handlers.reconnect.forEach(handler => handler())
        this.heartbeat()
      } else {
        console.log('reconnection unsuccsessful')
        this.reconnect()
      }
    }, 5000)
  }

  _pollConnection = () => {
    if (this.connection.readyState === 1) {
      this.connected = true
    }
  }

  _bindListeners = connection => {
    this.handlers.close.forEach(handler => connection.addEventListener('close', handler))
    this.handlers.error.forEach(handler => connection.addEventListener('error', handler))
    this.handlers.message.forEach(handler => connection.addEventListener('message', handler))
    this.handlers.open.forEach(handler => connection.addEventListener('open', handler))
    if (this.connection) {
      this.handlers.close.forEach(handler => this.connection.removeEventListener('close', handler))
      this.handlers.error.forEach(handler => this.connection.removeEventListener('error', handler))
      this.handlers.message.forEach(handler => this.connection.removeEventListener('message', handler))
      this.handlers.open.forEach(handler => this.connection.removeEventListener('open', handler))
    }
    this.connection = connection
  }
}

const baseURLs = {
  production: '//' + window.location.host,
  development: '//localhost:3005'
}

const baseURL = `${getValue(baseURLs)}/api`
const socketURL = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}:${getValue(baseURLs)}`

const socketAPI = new SocketClient(socketURL)
const restAPI = axios.create({ baseURL })

const updateToken = (token) => {
  restAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
  if (socketAPI.token !== token) {
    socketAPI.token = token
    if (socketAPI.connectInvoked) {
      socketAPI.reconnect()
    }
  }
}

export { socketAPI, restAPI, updateToken }

function getValue(from) {
  return from[process.env.NODE_ENV] || from.development
}
