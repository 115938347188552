import { connect } from 'react-redux'
import { UPDATE_RESPONSE } from '../ducks/responses'
import ResponseForm from '../components/ResponseForm'

const mapStateToProps = (state, { responseId }) => {
  return {
    loading: state.responses.creating,
    response: state.responses.byId[responseId]
  }
}

const mapDispatchToProps = (dispatch, { responseId }) => {
  return {
    handleSubmit: data => dispatch(UPDATE_RESPONSE({ ...data, _id: responseId }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseForm)
