import React, { useRef } from 'react'
import { Redirect } from 'react-router-dom'
import Button from '../Button'
import Input from '../Input'
import Form, { Field } from '../Form'
import styles from './LoginForm.module.scss'

const LoginForm = ({ handleLogin, loggingIn, loggedIn, location }) => {
  const formRef = useRef()

  const handleSubmit = ({ values, valid, warnings }) => {
    if (valid) {
      handleLogin(values)
    }
  }

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      // submit when return key is pressed
      formRef.current.submit(e)
    }
  }

  return (
    <div className={styles.root}>
      <Form ref={formRef} onSubmit={handleSubmit} className={styles.container}>
        <div className={styles.logo}>
          <img src='/images/bot_icon.png' alt='Bot Icon' />
        </div>
        <h1 className={styles.heading}>Please sign in</h1>
        <Field validations={['isEmail']}>
          <Input
            onKeyUp={handleKeyUp}
            style={{ width: '100%' }}
            type='email'
            icon='Mail'
            placeholder='Email Address'
            autoComplete='email-address'
            name='email' />
        </Field>
        <Field>
          <Input
            onKeyUp={handleKeyUp}
            style={{ width: '100%' }}
            type='password'
            icon='Lock'
            placeholder='Password'
            autoComplete='current-password'
            name='password' />
        </Field>
        <Button type='submit' loading={loggingIn}>
          {loggingIn ? 'Logging in...' : 'Log In'}
        </Button>
      </Form>
      <a
        className={styles.badge}
        href='https://www.flickr.com/photos/9558858@N02'
        rel='noopener noreferrer'
        title='"Circuit Board" by Dirkusmaximus'
        target='_blank'><img src='/images/cc.svg' alt='Creative Commons Badge' /></a>
      {loggedIn && <Redirect to={location.state && location.state.from && location.state.from.pathname ? location.state.from.pathname : '/'} />}
    </div>
  )
}

export default LoginForm
