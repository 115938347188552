import keyBy from 'lodash/keyBy'
import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import { restAPI } from '../api'

const { actions, reducer } = createSlice({
  initialState: {
    creating: false,
    updating: false,
    deleting: false,
    invalidated: true,
    loading: false,
    lastUpdated: null,
    lastEvaluated: null,
    byId: {}
  },
  reducers: {
    CREATE_RESPONSE(state, { payload }) {
      state.creating = true
    },
    CREATE_RESPONSES(state, { payload }) {
      state.creating = true
    },
    UPDATE_RESPONSE(state, { payload }) {
      state.updating = true
    },
    DELETE_RESPONSE(state, { payload }) {
      state.deleting = true
    },
    REQUEST_RESPONSES(state, { payload }) {
      state.loading = true
    },
    RESPONSE_CREATE_SUCCESS(state, { payload }) {
      state.creating = false
      state.byId = { ...state.byId, [payload._id]: payload }
    },
    RESPONSES_CREATE_SUCCESS(state, { payload }) {
      state.creating = false
      state.byId = { ...state.byId, ...keyBy(payload, '_id') }
    },
    RESPONSE_UPDATE_SUCCESS(state, { payload }) {
      state.update = false
      state.byId = { ...state.byId, [payload._id]: payload }
    },
    RESPONSE_DELETE_SUCCESS(state, { payload }) {
      state.deleting = false
      delete state.byId[payload._id]
    },
    RESPONSES_REQUEST_SUCCESS(state, { payload }) {
      state.invalidated = false
      state.loading = false
      state.lastUpdated = new Date().toString()
      state.byId = { ...state.byId, ...keyBy(payload.items, '_id') }
      state.lastEvaluated = payload.lastEvaluated
    },
    FILTER_RESPONSES(state, { payload }) {
      state.typeFilter = payload
    },
    RESPONSE_REQUEST_FAILED(state, { payload }) {
      state.loading = false
    }
  }
})

function * onCreateResponse({ payload }) {
  try {
    const { data } = yield call(
      restAPI.post, '/response', {
        response: payload.response,
        intentName: payload.intentName,
        phrases: payload.phrases,
        type: payload.type
      }
    )
    yield put(actions.RESPONSE_CREATE_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Response Created', text: "Don't forget to run build!" }))
  } catch ({ response }) {
    yield put(actions.RESPONSE_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onCreateResponses({ payload }) {
  try {
    const { data } = yield call(
      restAPI.post, '/responses', payload.map(item => ({
        response: item.response,
        intentName: item.intentName,
        phrases: item.phrases,
        type: item.type
      }))
    )
    yield put(actions.RESPONSES_CREATE_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Import successful' }))
  } catch ({ response }) {
    yield put(actions.RESPONSE_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onUpdateResponse({ payload }) {
  try {
    const { data } = yield call(
      restAPI.put, `/response/${payload._id}`, {
        response: payload.response,
        intentName: payload.intentName,
        phrases: payload.phrases,
        type: payload.type
      }
    )
    yield put(actions.RESPONSE_UPDATE_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Response Updated' }))
  } catch ({ response }) {
    yield put(actions.RESPONSE_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onDeleteResponse({ payload }) {
  try {
    yield call(restAPI.delete, `/response/${payload._id}`)
    yield put(actions.RESPONSE_DELETE_SUCCESS(payload))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Response Deleted' }))
  } catch ({ response }) {
    yield put(actions.RESPONSE_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestResponses({ payload }) {
  try {
    const { data } = yield call(
      restAPI.get, '/responses', {
        params: {
          limit: payload.limit,
          startAt: payload.startAt
        }
      }
    )
    yield put(actions.RESPONSES_REQUEST_SUCCESS(data))
  } catch ({ response }) {
    yield put(actions.RESPONSE_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestResponseFail({ payload }) {
  yield put(SHOW_MESSAGE({ status: 'error', title: payload.title, text: payload.message }))
}

export function * saga() {
  yield takeLatest('CREATE_RESPONSE', onCreateResponse)
  yield takeLatest('CREATE_RESPONSES', onCreateResponses)
  yield takeLatest('UPDATE_RESPONSE', onUpdateResponse)
  yield takeLatest('DELETE_RESPONSE', onDeleteResponse)
  yield takeLatest('REQUEST_RESPONSES', onRequestResponses)
  yield takeLatest('RESPONSE_REQUEST_FAILED', onRequestResponseFail)
}

export const { CREATE_RESPONSE, CREATE_RESPONSES, UPDATE_RESPONSE, DELETE_RESPONSE, REQUEST_RESPONSES, FILTER_RESPONSES } = actions

export default reducer
