import { connect } from 'react-redux'
import { CREATE_RESPONSES } from '../ducks/responses'
import { SHOW_MESSAGE } from '../ducks/messages'
import ImportResponses from '../components/ImportResponses'

const mapStateToProps = state => {
  return {
    loading: state.responses.creating
  }
}

const mapDispatchToProps = dispatch => {
  return {
    importResponses: items => dispatch(CREATE_RESPONSES(items)),
    showMessage: message => dispatch(SHOW_MESSAGE(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportResponses)
