import React from 'react'
import { connect } from 'react-redux'
import XLSX from 'xlsx'
import { Item as PopupItem } from '../components/Popup'
// import { FILTER_RESPONSES, REQUEST_RESPONSES, DELETE_RESPONSE } from '../ducks/responses'

const Component = ({responses}) => {
  const exportResponses = () => {
    const data = responses.map(response => {
      return {
        intentName: response.intentName,
        type: response.type,
        response: response.response,
        phrases: response.phrases.join(),
        createdAt: response.createdAt
      }
    })
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      data, {
        header: ['intentName', 'type', 'response', 'phrases', 'createdAt']
      })
    XLSX.utils.book_append_sheet(wb, ws, 'Responses');
    XLSX.writeFile(wb, 'responses.xls')
  }


  return (
    <PopupItem onClick={exportResponses}>
      Export
    </PopupItem>
  )
}

const mapStateToProps = state => {
  return {
    responses: Object.values(state.responses.byId).filter(response => !state.responses.typeFilter || response.type === state.responses.typeFilter).map(r => ({...r, key: r._id }))
  }
}

export default connect(mapStateToProps, null)(Component)
