import React, { Fragment, useRef } from 'react'
import { Item as PopupItem } from '../Popup'
import XLSX from 'xlsx'

const ImportResponses = ({ loading, importResponses, showMessage }) => {
  const fileInput = useRef()
  const handleChange = e => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = ee => {
      const data = ee.target.result
      const wb = XLSX.read(data, {type: 'binary'})
      const ws = wb.Sheets[wb.SheetNames[0]]
      const json = XLSX.utils.sheet_to_json(ws)
      if (json) {
        importResponses(json.map(item => ({
          intentName: item.intentName,
          response: item.response,
          phrases: item.phrases.split(','),
          type: item.type
        })))
      } else {
        showMessage({
          status: 'error',
          title: 'Something went wrong',
          text: 'Check the file and try again'
        })
      }
    }
    reader.readAsBinaryString(file)
  }

  const triggerClick = e => {
    if (!loading) {
      fileInput.current.click()
    }
  }

  return (
    <Fragment>
      <input
        accept='.xls,.xlsx'
        type='file'
        ref={fileInput}
        onChange={handleChange}
        style={{display: 'none'}} />
      <PopupItem style={{position: 'relative'}} onClick={triggerClick}>
        Import
      </PopupItem>
    </Fragment>
  )
}

export default ImportResponses
