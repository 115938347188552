import React, { useState, Fragment } from 'react'
import styles from './ResponseActions.module.scss'
import { FiMessageSquare } from 'react-icons/fi'
import Button from '../Button'
import Modal from '../Modal'
import Popup from '../Popup'
import CreateResponse from '../../containers/CreateResponse'
import ExportResponses from '../../containers/ExportResponses'
import ImportResponses from '../../containers/ImportResponses'
import TrainResponses from '../../containers/TrainResponses'

const ResponseActions = ({ createResponse, creating }) => {
  const [modalVisible, setModalVisibility] = useState(false)

  const hide = e => setModalVisibility(false)

  return (
    <React.Fragment>
      <div className={styles.root}>
        <h1 className={styles.title}><FiMessageSquare className={styles.titleIcon} /> Responses</h1>
        <Button className={styles.button} icon='Plus' onClick={e => setModalVisibility(true)}>
          Create Response
        </Button>
        <Popup content={(
          <Fragment>
            <TrainResponses />
            <ImportResponses />
            <ExportResponses />
          </Fragment>
        )}>
          <Button className={styles.button} icon='MoreVertical' secondary />
        </Popup>
      </div>
      <Modal visible={modalVisible} onHide={hide}>
        <CreateResponse hide={hide} />
      </Modal>
    </React.Fragment>
  )
}

export default ResponseActions
