import { connect } from 'react-redux'
import { BUILD_CHANNEL_START, BUILD_CHANNEL_STOP, REQUEST_BUILD_STATUS } from '../ducks/builds'
import BuildPane from '../components/BuildPane'

const mapStateToProps = state => {
  return {
    lastUpdated: state.builds.lastUpdated,
    timeStarted: state.builds.timeStarted,
    building: state.builds.building,
    online: state.builds.serverStatus === 'on',
    buildStatus: state.builds.buildStatus,
    error: state.builds.error,
    visible: state.builds.visible
  }
}

const mapDispatchToProps = dispatch => {
  return {
    connectWs: () => dispatch(BUILD_CHANNEL_START()),
    disconnectWs: () => dispatch(BUILD_CHANNEL_STOP()),
    load: () => dispatch(REQUEST_BUILD_STATUS())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildPane)
