import React from 'react'
import styles from './Table.module.scss'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import propTypes from 'prop-types'
import classNames from 'classnames'

const sortIcon = direction => direction === 'asc' ? <FiChevronUp /> : <FiChevronDown />

const HeaderCell = ({ column, onClick, sortable, currentSortIndex, currentSortDirection }) => {
  const canSort = sortable && column.dataIndex
  const className = classNames(
    { [styles.sortable]: canSort },
    { [styles.sorted]: currentSortIndex === column.dataIndex }
  )
  return (
    <div className={styles.headerCell}>
      <span onClick={canSort ? onClick(column.dataIndex) : undefined} className={className}>{column.title} {sortable && currentSortIndex === column.dataIndex && sortIcon(currentSortDirection)}</span>
    </div>
  )
}

HeaderCell.propTypes = {
  column: propTypes.shape({
    title: propTypes.any,
    dataIndex: propTypes.string
  }).isRequired,
  sortable: propTypes.bool,
  onClick: propTypes.func,
  currentSortIndex: propTypes.string,
  currentSortDirection: propTypes.oneOf(['asc', 'desc'])
}

export default HeaderCell
