import { createSlice } from 'redux-starter-kit'
import { SHOW_MESSAGE } from './messages'
import createSocketChannel from '../util/createSocketChannel'
import { call, put, race, takeLatest, takeEvery, take, delay, cancelled, fork } from 'redux-saga/effects'
import { socketAPI, restAPI } from '../api'

window.api = socketAPI

const { actions, reducer } = createSlice({
  initialState: {
    lastUpdated: null,
    serverStatus: 'unknown',
    channelStatus: 'off',
    building: false,
    visible: false,
    buildStatus: 'unknown',
    error: null,
    timeStarted: null
  },
  reducers: {
    REQUEST_BUILD_STATUS(state, { payload }) { },
    REQUEST_BUILD_STATUS_SUCCESS(state, { payload }) {
      state.buildStatus = payload.status
      state.error = payload.latestError
      if (!['NOT_BUILDING', 'ERROR'].includes(payload.status)) {
        state.building = true
        state.visible = true
      }
    },
    COMMENCE_BUILD(state, { payload }) {
      state.error = null
      state.lastUpdated = new Date().toString()
      state.timeStarted = new Date().toString()
      state.building = true
      state.buildStatus = 'STARTED'
      state.visible = true
    },
    BUILD_CHANNEL_START(state, { payload }) { },
    BUILD_CHANNEL_STOP(state, { payload }) { },
    BUILD_CHANNEL_ONLINE(state, { payload }) {
      state.channelStatus = 'on'
    },
    BUILD_CHANNEL_OFFLINE(state, { payload }) {
      state.channelStatus = 'off'
      state.serverStatus = 'offline'
    },
    BUILD_SERVER_ONLINE(state, { payload }) {
      state.serverStatus = 'on'
    },
    BUILD_SERVER_OFFLINE(state, { payload }) {
      state.serverStatus = 'off'
    },
    BUILD_PANE_REVEAL(state, { payload }) {
      state.visible = true
    },
    BUILD_PANE_HIDE(state, { payload }) {
      state.visible = false
    },
    BUILD_EVENT_RECEIVED(state, { payload }) {
      const { namespace, event, message } = JSON.parse(payload)
      if (namespace !== 'builds') return

      state.buildStatus = event
      state.lastUpdate = new Date().toString()

      if (['ERROR', 'COMPLETE'].includes(event)) {
        state.building = false
      }
      if (event === 'ERROR') {
        state.error = message
      }
    }
  }
})

function* onRequestBuildStatus({ payload }) {
  try {
    const { data } = yield call(
      restAPI.get, '/build'
    )
    yield put(actions.REQUEST_BUILD_STATUS_SUCCESS(data))
  } catch ({ response }) {
    /* eslint-disable-next-line */
    yield call(console.error, (response && response.data.message) || 'Something went wrong')
  }
}

function* onCommence({ payload }) {
  yield call(socketAPI.send, 'startBuild')
}

function* onDisconnect() {
  const disconnectChannel = yield call(createSocketChannel, socketAPI, 'close')
  try {
    yield takeLatest(disconnectChannel, function* () {
      yield put(actions.BUILD_SERVER_OFFLINE())
      if (!socketAPI.reconnecting) {
        yield call(socketAPI.reconnect)
      }
    })
  } catch (err) { }
}

function* onReconnect() {
  const reconnectChannel = yield call(createSocketChannel, socketAPI, 'reconnect')
  try {
    yield takeLatest(reconnectChannel, function* () {
      yield put(actions.BUILD_SERVER_ONLINE())
    })
  } catch (err) { }
}

function* onEvent(payload) {
  if (payload && JSON.parse(payload).namespace === 'builds') {
    if (JSON.parse(payload).event === 'COMPLETE') {
      yield put(SHOW_MESSAGE({ status: 'success', title: 'Build successful' }))
    }
    if (JSON.parse(payload).event === 'ERROR') {
      yield put(SHOW_MESSAGE({ status: 'error', title: 'Build failed', text: 'Check the build pane for more info' }))
    }
    yield put(actions.BUILD_EVENT_RECEIVED(payload))
  }
}

function* connectChannel() {
  try {
    // enable the channel and connect
    yield put(actions.BUILD_CHANNEL_ONLINE())
    yield call(socketAPI.connect)
    const { timeout } = yield race({
      connected: call(socketAPI.onAsync, 'open'),
      timeout: delay(5000)
    })
    if (timeout) {
      // connect failed
      yield put(actions.BUILD_SERVER_OFFLINE())
    } else {
      yield put(actions.BUILD_SERVER_ONLINE())
    }

    // process data events
    const messageChannel = yield call(createSocketChannel, socketAPI, 'message')

    // listen for connection events
    yield fork(onDisconnect)
    yield fork(onReconnect)

    yield takeEvery(messageChannel, onEvent)
  } catch (err) { } finally {
    if (yield cancelled()) {
      yield put(actions.BUILD_CHANNEL_OFFLINE())
    }
  }
}

function* startChannel() {
  yield race({
    task: call(connectChannel),
    cancel: take('BUILD_CHANNEL_STOP')
  })
}

export function* saga() {
  yield takeLatest('REQUEST_BUILD_STATUS', onRequestBuildStatus)
  yield takeLatest('BUILD_CHANNEL_START', startChannel)
  yield takeLatest('COMMENCE_BUILD', onCommence)
}

export const { BUILD_CHANNEL_START, COMMENCE_BUILD, REQUEST_BUILD_STATUS, BUILD_CHANNEL_STOP } = actions

export default reducer
