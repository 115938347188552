import React, { Fragment, useState } from 'react'
import startCase from 'lodash/startCase'
import ResponseActions from '../ResponseActions'
import EditResponse from '../../containers/EditResponse'
import Table from '../Table'
import Button from '../Button'
import Modal from '../Modal'
import Radios from '../Radios'
import Popup, { Item as PopupItem } from '../Popup'

const RowActions = ({response, deleteResponse}) => {
  const [modalVisible, setModalVisibility] = useState(false)

  const hide = e => setModalVisibility(false)

  return (
    <Fragment>
      <Popup 
        style={{float: 'right'}}
        content={
          <Fragment>
            <PopupItem onClick={() => setModalVisibility(true)}>Edit</PopupItem>
            <PopupItem onClick={() => deleteResponse(response)}>Delete</PopupItem>
          </Fragment>
        }
      >
        <Button icon='MoreVertical' outline size='small' />
      </Popup>
      <Modal visible={modalVisible} onHide={hide}>
        <EditResponse responseId={response._id} hide={hide} />
      </Modal>
    </Fragment>
  )
}

class Responses extends React.Component {
  constructor (props) {
    super(props)
    props.load({
      limit: 30
    })
  }

  tableCols = [
    {
      title: 'Intent',
      dataIndex: 'intentName',
      key: 'intentName'
    },
    {
      title: 'Phrases',
      dataIndex: 'phrases',
      key: 'phrases',
      render: ({phrases}) => phrases.map(phrase => (
        <Fragment key={phrase}>
          {phrase}<br />
        </Fragment>
      ))
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
      render: ({response}) => response ? response : <em>(Defined in Basebot)</em>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: ({type}) => startCase(type)
    },
    {
      title: <Radios onChange={type => this.props.filterTypes(type)} options={[ { value: '', text: 'All' }, { value: 'simple', text: 'Simple' }, { value: 'custom', text: 'Custom' }]} />,
      key: 'actions',
      render: (response) => <RowActions response={response} deleteResponse={this.props.deleteResponse} />
    }
  ]

  render () {
    const { responses, loading } = this.props

    return (
      <div>
        <ResponseActions />
        <Table 
          columns={this.tableCols}
          loading={loading}
          data={responses}
          loadingBars={5}
          noContent={{
            title: 'Add responses to get started',
            text: 'Responses are the building blocks of your bot',
            image: '/images/no-responses.png'
          }}
        />
      </div>
    )
  }
}

export default Responses
