import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import styles from './ResponseForm.module.scss'
import Form, { Field } from '../Form'
import Input from '../Input'
import Select from '../Select'
import Button from '../Button'

const CreateResponse = ({ response, handleSubmit, loading, hide }) => {
  const [type, setType] = useState('simple')

  const onSubmit = ({ values, valid }) => {
    if (!valid) return
    handleSubmit(values)
    hide()
  }

  return (
    <Form onSubmit={onSubmit} className={styles.root}>
      <h2 className={styles.full}>{response ? 'Edit Response' : 'New Response'}</h2>
      <Field label="Intent Name" validations={['isAlpha']} className={styles.half}>
        <Input
          type="text"
          placeholder="e.g. greeting"
          name="intentName"
          defaultValue={response ? response.intentName : ''} />
      </Field>
      <Field
        label="Type"
        onChange={e => setType(e.target.value)}
        required
        className={styles.half}>
        <Select name="type" options={[{ value: 'simple', text: 'Simple' }, { value: 'custom', text: 'Custom' }]} defaultValue={response ? { text: startCase(response.type), value: response.type } : { value: 'simple', text: 'Simple' }} />
      </Field>
      <Field label="Example Phrases" className={styles.full} required validations={[{
        rule: 'matches',
        options: [
          /^[a-zA-Z0-9\s_'-]*$/
        ],
        message: 'Phrases should only contain letters, numbers, spaces, underscores, hyphens and apostrophes'
      }]}>
        <Select
          name="phrases"
          options={[]}
          placeholder="e.g. hey, hello, hi"
          defaultValue={response ? response.phrases.map(phrase => ({ text: phrase, value: phrase })) : []}
          multiple
          searchable
          allowCustom />
      </Field>
      {type === 'simple' && (
        <Field label="Response" required className={styles.full}>
          <Input
            name="response"
            type="textarea"
            defaultValue={response ? response.response : ''}
            placeholder="e.g. Nice to meet you!"
            style={{ height: '20rem' }} />
        </Field>
      )}
      <Button type="submit" disabled={loading} className={styles.button}>
        {response
          ? 'Update'
          : 'Create'}
      </Button>
    </Form>
  )
}

export default CreateResponse
