import React from 'react'
import BodyCell from './BodyCell'
import styles from './Table.module.scss'
import LoadingBlock from '../Loadingblock'

const LoadingRow = ({ columnCount }) => (
  <div className={styles.row}>
    {new Array(columnCount).fill(0).map((__, i) => (
       <BodyCell key={i}>
         <LoadingBlock />
       </BodyCell>
     ))}
  </div>
)

export default LoadingRow
