import React from 'react'
import { connect } from 'react-redux'
import { Item as PopupItem } from '../components/Popup'
import { COMMENCE_BUILD } from '../ducks/builds'

const Component = ({ train, building }) => {
  return (
    <PopupItem disabled={building} onClick={train}>
      Build
    </PopupItem>
  )
}

const mapStateToProps = state => {
  return {
    responses: Object.values(state.responses.byId).filter(response => !state.responses.typeFilter || response.type === state.responses.typeFilter).map(r => ({ ...r, key: r._id })),
    building: state.builds.building || state.builds.channelStatus !== 'on' || state.builds.serverStatus !== 'on'
  }
}

const mapDispatchToProps = dispatch => {
  return {
    train: () => dispatch(COMMENCE_BUILD())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
