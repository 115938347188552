import { connect } from 'react-redux'
import { CREATE_RESPONSE } from '../ducks/responses'
import ResponseForm from '../components/ResponseForm'

const mapStateToProps = state => {
  return {
    loading: state.responses.creating
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleSubmit: data => dispatch(CREATE_RESPONSE(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseForm)
