import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FiMessageSquare, FiLogOut } from 'react-icons/fi'
import styles from './Nav.module.scss'

const Nav = ({ logout }) => (
  <nav className={styles.root}>
    <Link to='/'>
    <img className={styles.logo} src='/images/bot_icon.png' alt='Bot Icon' />
    </Link>
    <div className={styles.links}>
      <NavLink to='/' activeClassName={styles.activeLink}>
        <div className={styles.link}>
          <FiMessageSquare /> <span>Manage Responses</span>
        </div>
      </NavLink>
      <div className={styles.link} onClick={logout}>
        <FiLogOut /> <span>Log out</span>
      </div>
    </div>
  </nav>
)

export default Nav
